import React from 'react';
import ReactToPrint from 'react-to-print';

class ComponentToPrint extends React.Component {
  render() {
    return (
      <div>
        <h1>Hello, world!</h1>
        <p>This is the content you want to print.</p>
      </div>
    );
  }
}

class PrintComponent extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => <button>Print</button>}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

export default PrintComponent;
