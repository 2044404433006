import React, { useState, useEffect, useMemo } from "react";
import "../../../assets/css/jocker/spin.css";
import { Wheel } from "react-custom-roulette";
import newlogo from "../../../assets/image/logBigLg.png";
import SpinFooter from "./SpinFotter";
import SpinButtons from "./SpinButtons";
import { NavLink } from "react-router-dom";
import SpinRoulatte from "./SpinRoulatte";
import FireWorkreact from "../../../contanier/FireWorkreact";
import RetailerBonusPop from "../../RetalierBonus/RetailerBonusPop";
import Modal from "./Modal";
import AdvanceDrawSpin from "./AdvanceDrawSpin";
import { useDispatch, useSelector } from "react-redux";
import sremove from "../../../assets/image/sws.png";
import watch from "../../../assets/image/Wt.png";

const SpinMain = ({
  selectedCoinAmount,
  handleTotalAmount,
  playButtonClickSound,
  playSoundSpinner,
  handleButtonChange,
  apiResponse,
  fetchUserDetails,
  onChnagequntynums,
  selectedSlots,
  setSelectedSlots,
  selectedNumbers,
  setSelectedNumbers,
  spinNumber,
  mustSpin,
  setMustSpin,
  currentSession,
  fetchUserSessionDetails,
}) => {
  const { token, userId } = useSelector((state) => state.game);
  const [currentSlotTime, setCurrentSlotTime] = useState(
    (apiResponse && apiResponse.Next_Session) || "0:0"
  );
  const [timeLeftForSlot, setTimeLeftForSlot] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState(0);

  useEffect(() => {
    const sum = Object.values(selectedNumbers).reduce(
      (acc, curr) => acc + curr.count,
      0
    );
    const amounts = sum ? sum * 10 : "";
    setAmount(selectedSlots.length > 0 ? selectedSlots.length * amounts : 1 * amounts);
  }, [selectedNumbers, selectedCoinAmount, selectedSlots]);

  useEffect(() => {
    if (apiResponse) {
      setCurrentSlotTime(apiResponse.Next_Session);
    }
  }, [apiResponse]);

  const handleCloseModal = () => {
    fetchUserSessionDetails();
    setShowModal(false);
  };

  const initiateSpinWheel = () => {
    function getNext(num, dep) {
      return (num % dep ? dep : 0) - (num % dep) + num;
    }

    const currentTime = new Date();
    let nextSlotTime = new Date();

    const dep = nextSlotTime.getMinutes() % 3;
    const sec = nextSlotTime.getSeconds();
    if (dep === 0 && sec > 16) {
      nextSlotTime.setMinutes(nextSlotTime.getMinutes() + 3, 0);
    } else {
      nextSlotTime.setMinutes(getNext(nextSlotTime.getMinutes(), 3), 0);
    }
    // console.log(nextSlotTime, currentTime);
    if (nextSlotTime > currentTime) {
      const pendingTime = nextSlotTime - currentTime;
      if (pendingTime <= 1000 * 8) setShowModal(true);

      const remainingMinutes = Math.floor(pendingTime / (1000 * 60));
      const remainingSeconds = Math.floor((pendingTime % (1000 * 60)) / 1000);

      if (remainingMinutes < 1) {
        setTimeLeftForSlot(`${remainingSeconds}`);
      } else {
        setTimeLeftForSlot(
          `${remainingMinutes}:${remainingSeconds.toString().padStart(2, "0")}`
        );
      }
    } else setTimeLeftForSlot(0);
  };

  useEffect(() => {
    const spinInterval = setInterval(initiateSpinWheel, 1000);

    return () => {
      clearInterval(spinInterval);
    };
  }, []);

  const getTime = useMemo(() => {
    const { winning } = apiResponse || {};

    return (
      winning &&
      winning != "" &&
      winning?.map(({ win, datetime }, index) => {
        const [date, time] = datetime.split(" ");
        const [hour, minute, second] = time.split(":");

        const formattedHour = hour % 12 || 12;

        const period = hour >= 12 ? "PM" : "AM";
        const formattedTime = `${formattedHour}:${minute} ${period}`;
        return (
          <div
            key={index}
            className="d-flex timevaluemargin"
            style={{
              flexDirection: "column",
            }}
          >
            <div className="boxTime" style={{ margin: "4px" }}>
              {formattedTime}
            </div>
            <div className="boxvalues" style={{ margin: "4px" }}>
              {" "}
              {parseInt(win)}
            </div>
          </div>
        );
      })
    );
  }, [apiResponse]);

  const roulette = useMemo(() => (
    <SpinRoulatte
      spinNumber={spinNumber}
      playSoundSpinner={playSoundSpinner}
      currentSession={currentSession}
      stopSpin={() => {
        fetchUserDetails();
        return setMustSpin(false);
      }}
    />
  ));
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="container-fluid" style={{ height: "85vh" }}>
        <div className="SpinMain">
          <div className="row" style={{ marginBottom: "10PX" }}>
            <div className="col-4" style={{ height: "70vh" }}>
              <div
                style={{
                  height: "22vh",
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <div className="row">
                  <div className="d-flex">
                    <div className="boxspin" style={{ margin: "4px" }}>
                      RETAILER NAME
                    </div>
                    <div className="boxspin" style={{ margin: "4px" }}>
                      {(apiResponse && apiResponse.name) || ""}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex">
                    <div className="boxspin" style={{ margin: "4px" }}>
                      BALANCE
                    </div>
                    <div className="boxspin" style={{ margin: "4px" }}>
                      {(apiResponse && apiResponse.wallet_bal) || ""}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex">
                    <div className="boxspin" style={{ margin: "4px" }}>
                      CURRENT SLOT
                    </div>
                    <div className="boxspin" style={{ margin: "4px" }}>
                      {(apiResponse && apiResponse?.Next_Session) || ""}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "47vh",
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <div
                  className="row webkitscroll"
                  style={{ overflowY: "auto", maxHeight: "335px" }}
                >
                  <div className="col-12">
                    <div
                      className=""
                      style={{
                        margin: "10px 5px",
                        border: "1px solid goldenrod",
                      }}
                    >
                      <div
                        className="d-flex"
                        style={{
                          justifyContent: "space-evenly",
                          flexWrap: "wrap",
                        }}
                      >
                        {getTime}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-4"
              style={{
                height: "70vh",
                display: "flex",
                justifyContent: "center",
                margin: "auto",
                alignItems: "center",
              }}
            >
              <div className="" style={{ position: "relative" }}>
                <img src={sremove} className="sremove"></img>
                <div
                  className="roultee"
                  style={{ position: "absolute", top: "36px", left: "13%" }}
                >
                  {roulette}
                </div>
              </div>
            </div>
            <div className="col-4 " style={{ height: "70vh" }}>
              <div
                style={{
                  height: "15vh",
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <div className="row">
                  <div className="d-flex">
                    <div
                      className="boxspins"
                      style={{ margin: "4px" }}
                      onClick={() => {
                        handleButtonClick();
                        playButtonClickSound();
                      }}
                    >
                      ADVANCE DRAW-{selectedSlots.length}
                    </div>
                    <div className="boxspins" style={{ margin: "4px" }}>
                      <NavLink
                        className="navdecoration"
                        to={`https://buthiosrtl.app/laptop/gdgame1?token=${token}&user_id=${userId}`}
                      >
                        GOLD-COIN
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="d-flex">
                    <div className="boxspins" style={{ margin: "4px" }}>
                      <NavLink
                        className="navdecoration"
                        onClick={() => {
                          playButtonClickSound();
                        }}
                        to={`https://buthiosrtl.app/flowt/retailers1/titligame?token=${token}&user_id=${userId}`}
                      >
                        {" "}
                        TITLI-GHODA
                      </NavLink>
                    </div>
                    <div className="boxspins" style={{ margin: "4px" }}>
                      <NavLink
                        className="navdecoration"
                        onClick={() => {
                          playButtonClickSound();
                        }}
                        to={`https://buthiosrtl.app/laptop_lucky/g/3dgame?token=${token}&user_id=${userId}`}
                      >
                        {" "}
                        3D-GAME{" "}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  border: "1px solid goldenrod",
                  height: "55vh",
                  display: "flex",
                  justifyContent: "center",
                  margin: "auto",
                  flexDirection: "column",
                }}
              >
                <div className="row announcement-text">Amusement only</div>
                <div className="row" style={{ margin: "10px" }}>
                  <div className="col-6 spintextdiv">
                    <div className="spin-text spinntext">
                      {" "}
                      Spin <br />
                      To <br />
                      Win
                    </div>
                  </div>
                  <div className="col-6 spintextdiv">
                    <div
                      className="spinntext spin-text"
                      style={{ fontSize: "2rem" }}
                    >
                      {" "}
                      Time Left
                    </div>

                    <div
                      className=""
                      style={{
                        position: "relative",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <img src={watch} className="watch" />
                      <div
                        className="timerights"
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          paddingTop: 23,
                        }}
                      >
                        <div className="slotleftsec"> {timeLeftForSlot}</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{ margin: "10px" }}>
                  <div className="col-8">
                    <div className="row antyamnt">
                      <div className="col-3 qtyamnt">
                        <div className="qtyamtname ">QTY</div>
                      </div>
                      <div className="col-9">
                        <input
                          className="form-control qtytexttt"
                          value={Object.values(selectedNumbers)
                            .map((sn) => sn.count)
                            .join(",")}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row antyamnt">
                      <div className="col-3 qtyamnt">
                        <div className="qtyamtname">Amt</div>
                      </div>
                      <div className="col-9">
                        <input
                          type="text"
                          className="form-control qtytexttt"
                          value={amount}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <SpinButtons
            selectedNumbers={selectedNumbers}
            setSelectedNumbers={setSelectedNumbers}
            playButtonClickSound={playButtonClickSound}
          />
        </div>
      </div>
      {isModalOpen && (
        <AdvanceDrawSpin
          apiResponse={apiResponse}
          onHide={handleModalClose}
          selectedSlots={selectedSlots}
          setSelectedSlots={setSelectedSlots}
        />
      )}
      {showModal && (
        <>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              height: "100vh",
              width: "99vw",
              backgroundColor: "transparent",
              overflow: "hidden",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              backgroundColor: "black",
              opacity: "0.7",
              overflow: "hidden",
              height: "100vh",
              width: "100vw",
            }}
          >
            <FireWorkreact />
          </div>

          <Modal onClose={handleCloseModal} />
        </>
      )}
    </>
  );
};

export default SpinMain;
