import React from 'react';
import "../../assets/css/GoldCoin/tablecopm.css"

class TableComponent extends React.Component {
    render() {
        // Parse the HTML table structure
        const tableData = [
            {
                id: "resultdeclareid1",
                numbers: [
                    { color: "color-2", value: "1094" },
                    { color: "color-3", value: "1120" },
                    { color: "color-2", value: "1094" },
                    { color: "color-3", value: "1120" },
                    { color: "color-2", value: "1094" },
                    { color: "color-3", value: "1120" },
                    { color: "color-2", value: "1094" },
                    { color: "color-3", value: "1120" },
                    { color: "color-3", value: "1120" },
                    // Add more numbers as needed
                ]
            },
            // Add more rows as needed
        ];

        // Map tableData to React elements
        const tableRows = tableData.map((row, index) => (
            <tr key={index} style={{ lineHeight: "0.7", display: "block" }}>
                {row.numbers.map((number, i) => (
                    <td key={i} style={{ width: "10%", textAlign: "center" }}>
                        <button style={{ fontSize: '28px' }} className='cll'>
                            {number.value}
                        </button>
                    </td>
                ))}
            </tr>
        ));

        return (
            <table width="80%">
                <tbody>
                    <tr>
                        <td width="100%">
                            <table width="100%">
                                <tbody>
                                    {tableRows}
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
}

export default TableComponent;
