import React from "react";
import luckyPic from "../../../assets/image/nlog.png";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const SpinHeader = ({ apiResponse, playButtonClickSound }) => {
  const { token } = useSelector((state) => state.game);
  const { userId } = useSelector((state) => state.game);

  const buttons2 = [
    {
      label: "Reprint",
      className: "btn-danger",
      href: "https://buthiosrtl.app/ngame/laptop_joker/reprint1",
    },
    // { label: 'Report', className: 'btn-primary',href: '../../chngepaswd'},
    {
      label: "Cancel",
      className: "btn-warning",
      href: "https://buthiosrtl.app/ngame/laptop_joker/cancel_tick1",
    },
    {
      label: "Result",
      className: "btn-danger",
      href: "https://buthiosrtl.app/ngame/laptop_joker/results1",
    },

    { label: "Logout", className: "btn-danger", href: "../../" },
  ];

  const handleButtonClicks = (link) => {
    const query = `?token=${token}&user_id=${userId}`;
    window.location.href = link + query;
  };
  return (
    <>
      <div className="container-fluid">
        <div className="row route">
          <div className="col-2" style={{ height: "6vh" }}>
            <div>
              <img src={luckyPic} style={{ height: "6vh", width: "100%" }} />
            </div>
          </div>
          <div className="col-8" style={{ margin: "auto" }}>
            <div className="row">
              <div className="" style={{ display: "flex" }}>
                {buttons2.map((button, index) => (
                  <button
                    key={index}
                    className="custom-button"
                    onClick={() => {
                      handleButtonClicks(button.href);
                      playButtonClickSound();
                    }}
                  >
                    {/* <NavLink className="navdecoration" to={button.href}><b>{button.label}</b></NavLink> */}
                    {button.label}
                  </button>
                ))}
              </div>
            </div>
          </div>
          <div className="col-2" style={{ margin: "auto" }}>
            <div className="transaction">{apiResponse?.Last_sale_id}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SpinHeader;
