import React, { useState, useEffect, memo } from "react";
import { Wheel } from "react-custom-roulette";

const data = [
  { option: "1", style: { backgroundColor: "black", textColor: "white" } },
  { option: "2", style: { backgroundColor: "red", textColor: "white" } },
  { option: "3", style: { backgroundColor: "black", textColor: "white" } },
  { option: "4", style: { backgroundColor: "red", textColor: "white" } },
  { option: "5", style: { backgroundColor: "black", textColor: "white" } },
  { option: "6", style: { backgroundColor: "red", textColor: "white" } },
  { option: "7", style: { backgroundColor: "black", textColor: "white" } },
  { option: "8", style: { backgroundColor: "red", textColor: "white" } },
  { option: "9", style: { backgroundColor: "black", textColor: "white" } },
  { option: "10", style: { backgroundColor: "red", textColor: "white" } },
  { option: "11", style: { backgroundColor: "black", textColor: "white" } },
  { option: "12", style: { backgroundColor: "red", textColor: "white" } },
];

const RouletteWheel = memo(
  ({ spinNumber, stopSpin, playSoundSpinner, currentSession }) => {

    const [spin, setSpin] = useState(false);
    const [session, setSession] = useState(0);

    useEffect(() => {
      // console.log('UseEffect - ', spinNumber, currentSession, session);
      if (currentSession > session) {
        playSoundSpinner();
        setSession(currentSession);
        setSpin(true);
      }
      if (session === 0) setSession(currentSession);
    }, [spinNumber, currentSession]);

    return (
      <>
      {/* {console.log(spin, spinNumber, currentSession)} */}
        <Wheel
          mustStartSpinning={spin}
          prizeNumber={Number(spinNumber)-1}
          data={data}
          outerBorderColor="goldenrod"
          outerBorderWidth="10"
          innerBorderColor="goldenrod"
          fontSize="35"
          fontWeight="bold"
          radiusLineWidth="6"
          innerBorderWidth="16"
          perpendicularText={true}
          // radiusLineColor="white"
          onStopSpinning={() => {
            setSpin(false);
            stopSpin();
          }}
        />
      </>
    );
  }
);

export default RouletteWheel;
