import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import "../../../assets/css/GoldCoin/advancedraw.css";

const AdvanceDrawSpin = ({
  onHide,
  apiResponse,
  selectedSlots,
  setSelectedSlots,
}) => {
  const Next_Session = apiResponse.Next_Session;
  const previous_session = apiResponse.previous_session;
  const totalsession = 260;
  const unselectedsession = totalsession - previous_session;

  const [timeSlots, setTimeSlots] = useState([]);
  const [upcomingNextSession, setUpcomingNextSession] = useState("");

  const addMinutesToTime = (time, minutesToAdd) => {
    if (!time) return "";
    const [timeStr, period] = time.split(/(?=[ap]m)/i);
    let [hour, minute] = timeStr.split(":").map(Number);
    if (period?.toLowerCase() === "pm" && hour !== 12) {
      hour += 12;
    } else if (period?.toLowerCase() === "am" && hour === 12) {
      hour = 0;
    }
    minute += minutesToAdd;
    while (minute >= 60) {
      hour++;
      minute -= 60;
    }
    if (hour >= 24) {
      hour -= 24;
    }
    const newPeriod = hour >= 12 ? "PM" : "AM";
    if (hour > 12) hour -= 12;
    if (hour === 0) hour = 12;
    return `${hour.toString().padStart(2, "0")}:${minute
      .toString()
      .padStart(2, "0")}${newPeriod.toLowerCase()}`;
  };

  useEffect(() => {
    setUpcomingNextSession(addMinutesToTime(Next_Session, 3));
  }, [Next_Session]);

  const generateTimeSlots = () => {
    const timeSlots = [];
    const [time, period] = upcomingNextSession.split(/(?=[ap]m)/i);
    if (!time || !period) return [];
    let [hourString, minuteString] = time.split(":");
    let hour = parseInt(hourString);
    let minute = parseInt(minuteString);
    if (period.toLowerCase() === "pm" && hour !== 12) {
      hour += 12;
    }

    while (hour < 22 || (hour === 22 && minute <= 0)) {
      timeSlots.push({
        hour,
        minute,
        sessionID: previous_session + timeSlots.length,
      });
      minute += 3;
      if (minute >= 60) {
        hour++;
        minute = 0;
      }
    }

    return timeSlots;
  };

  useEffect(() => {
    setTimeSlots(generateTimeSlots());
  }, [upcomingNextSession]);

  const handleCheckboxClick = (index) => {
    const updatedSlots = [...selectedSlots];
    const slot = timeSlots[index];
    const selectedIndex = selectedSlots.findIndex(
      (selectedSlot) => selectedSlot.sessionID === slot.sessionID
    );
    if (selectedIndex === -1) {
      updatedSlots.push(slot);
    } else {
      updatedSlots.splice(selectedIndex, 1);
    }
    setSelectedSlots(updatedSlots);
  };

  const handleSelectAll = () => {
    if (selectedSlots.length === timeSlots.length) {
      setSelectedSlots([]);
    } else {
      setSelectedSlots([...timeSlots]);
    }
  };

  const handleInputChange = (event) => {
    const inputValue = parseInt(event.target.value);
    if (!isNaN(inputValue) && inputValue >= 1 && inputValue <= 260) {
      setSelectedSlots(timeSlots.slice(0, inputValue));
    }
  };

  const formatTime = (hour, minute) => {
    return new Date(0, 0, 0, hour, minute).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  return (
    <div id="light" className="content" style={{ display: "block" }}>
      <div className="">
        <span>
          <center>
            <span style={{ fontSize: "35px", fontWeight: "700" }}>
              Advance Draw
            </span>
          </center>
          <span style={{ float: "right" }}>
            <div className="close" onClick={onHide} to="/goldcoin-main">
              OKAY
            </div>
          </span>
        </span>
        <br />

        <span>Remaining Time Slots: {unselectedsession}</span>
        <br />
        <br />
     
        <form>
          <input
            type="text"
            id="advvinv"
            style={{ height: "40px" }}
            autoComplete="off"
            onChange={handleInputChange}
          />
          &nbsp;&nbsp;&nbsp;
          <input
            type="button"
            style={{
              height: "40px",
              fontSize: "18px",
              background: "#07318f",
              color: "#fff",
            }}
            value="Select All"
            onClick={handleSelectAll}
          />
          &nbsp;&nbsp;
          <br />
          <br />
          <div className="leb">
            {timeSlots.map((slot, index) => (
              <React.Fragment key={index}>
                {index >= 0 && (
                  <>
                    {index % 7 === 0 && index !== 0 && <br />}
                    <label
                      className="checkbox-inline"
                      style={{ padding: "0px 5px" }}
                    >
                      <input
                        type="button"
                        style={{
                          width: "100%",
                          height: "40px",
                          fontSize: "18px",
                          background: selectedSlots.some(
                            (selectedSlot) =>
                              selectedSlot.sessionID === slot.sessionID
                          )
                            ? "black"
                            : "#07318f",
                          color: "#fff",
                          padding: "5px 20px",
                        }}
                        onClick={() => handleCheckboxClick(index)}
                        value={formatTime(slot.hour, slot.minute)}
                      />
                      &nbsp;&nbsp;
                      <span style={{ fontSize: "20px" }}></span>&nbsp;&nbsp;
                    </label>
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdvanceDrawSpin;
