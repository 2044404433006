import { createSlice } from '@reduxjs/toolkit';

export const gameDataSlice = createSlice({
    name: 'homepage',
    initialState: {
        token: "", 
        userId: "", 
        Jdata: {}
    },
    reducers: {
        saveToken: (state, action) => {
            state.token = action.payload;
        },
        saveUserId: (state, action) => {
            state.userId = action.payload;
        },
        jokerdata: (state, action) => {
            state.Jdata = action.payload;
        },
    },
});

export const {
    saveToken,
    saveUserId,
    jokerdata
} = gameDataSlice.actions;

export default gameDataSlice.reducer;
