import React from 'react'
import "../assets/css/Login/login.css"

const CustumButton = () => {
  return (
    <div>
         <button type="submit" className="login-button">Login</button>
    </div>
  )
}

export default CustumButton
