import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./contanier/Login/Login";
// import TitliGoda from "./contanier/TitliGhodaMain/TitliGoda";
import ThreeDGame from "./contanier/ThreeDGame/ThreeDGame";
import GoldCoinPage from "./contanier/GoldCoinMain/GoldCoinPage";
import Titlighodahead from "./contanier/TitliGhodaMain/Titlighodahead";
import JockerMainPannel from "./contanier/jocker/JockerMainPannel";
import ChangePassword from "./contanier/ChangePassword";
import RetalierBonus from "../src/contanier/RetalierBonus";
import RePrintComp from "./contanier/GoldCoinMain/RePrintComp";
import ReCancelComp from "./contanier/GoldCoinMain/ReCancelComp";
import Statement from "./contanier/GoldCoinMain/Statement";
import Account from "./contanier/GoldCoinMain/Account";
import AdvanceDraw from "./contanier/GoldCoinMain/AdvanceDraw";
import Result from "./contanier/GoldCoinMain/Result";
import GameChart from "./contanier/GoldCoinMain/GameChart";
import ResultTitli from "./contanier/TitliGhodaMain/ResultTitli";
import CancelTitli from "./contanier/TitliGhodaMain/CancelTitli";
import ReprintTitli from "./contanier/TitliGhodaMain/ReprintTitli";
import CancelledTitli from "./contanier/TitliGhodaMain/CancelledTitli";
import ReportTitli from "./contanier/TitliGhodaMain/ReportTitli";
import Statementtitli from "./contanier/TitliGhodaMain/Statementtitli";
import TableComponent from "./contanier/GoldCoinMain/TableComp";
import CancvelTicketTD from "./contanier/ThreeDGame/CancvelTicketTD";
import ReprintTD from "./contanier/ThreeDGame/ReprintTD";
import ResultTD from "./contanier/ThreeDGame/ResultTD";
import HowToPlay from "./contanier/ThreeDGame/HowToPlay";
import CancelTJoker from "./contanier/jocker/CancelTJoker";
import ResultJoker from "./contanier/jocker/ResultJoker";
import GoldMianCoin from "./contanier/GoldCoinMain/GoldMianCoin";
import ThreeMain from "./contanier/ThreeDGame/ThreeMain";
import JockMain from "./contanier/jocker/JockMain";
import Titlimain from "./contanier/TitliGhodaMain/Titlimain";
import Retalierbnspop from "../src/contanier/RetalierBonus/RetailerBonusPop"
import PrintLibrary from "./contanier/PrintLibrary/PrintLibrary";
import MainWindow from "./contanier/MainWindow";
import MainWindowForm from "./contanier/MainWindowForm";
import Fireworks from "@fireworks-js/react";
import Reactfire from "../src/contanier/FireWorkreact"
import TitliGhodaUi from "./contanier/TitliGhodaUI/TitliGhodaUi";
import JockerUi from "./contanier/jocker/JokerUi/JockerUi";
import Spin from "./contanier/jocker/Spin/Spin";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />

          {/* <Route path="goldcoin-main" element={<GoldMianCoin />} />

          <Route path="jocker-mains" element={<JockMain />} />
          <Route path="threedgame-main" element={<ThreeMain />} />
          <Route path="titlighoda-mains" element={<Titlimain />} />
          <Route path="chngepaswd" element={<ChangePassword />} />
          <Route path="retalierbns" element={<RetalierBonus />} />
          <Route path="retalierbnspop" element={<Retalierbnspop />} />
          <Route path="reprint" element={<RePrintComp />} />
          <Route path="recancel" element={<ReCancelComp />} />
          <Route path="statement" element={<Statement />} />
          <Route path="account" element={<Account />} />
          <Route path="advancedraw" element={<AdvanceDraw />} />
          <Route path="result" element={<Result />} />
          <Route path="gamechart" element={<GameChart />} />
          <Route path="titliresult" element={<ResultTitli />} />
          <Route path="titlicancel" element={<CancelTitli />} />
          <Route path="titlireprint" element={<ReprintTitli />} />
          <Route path="titlicancelled" element={<CancelledTitli />} />
          <Route path="titlireport" element={<ReportTitli />} />
          <Route path="titlistatmrnt" element={<Statementtitli />} />
          <Route path="tablecmp" element={<TableComponent />} />
          <Route path="cancelttd" element={<CancvelTicketTD />} />
          <Route path="reprintttd" element={<ReprintTD />} />
          <Route path="resuiltttd" element={<ResultTD />} />
          <Route path="howtoplay" element={<HowToPlay />} />
          <Route path="canceltjoekr" element={<CancelTJoker />} />
          <Route path="resultTjoker" element={<ResultJoker />} />
          <Route path="printlinrary" element={<PrintLibrary />} />
          <Route path="mainwindow" element={<MainWindow />} />
          <Route path="mainwindowform" element={<MainWindowForm />} />
          <Route path="fireworks" element={<Reactfire />} />
          <Route path="titlighoda-main" element={<TitliGhodaUi />} /> */}
          <Route path="jocker-main" element={<Spin />} />

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
