import React, { useState, useEffect } from "react";
import axios from "axios";
import SpinHeader from "./SpinHeader";
import SpinMain from "./SpinMain";
import SpinFooter from "../Spin/SpinFotter";
import btnmusic from "../../../assets/music/click.mp3";
import spinSound from "../../../assets/music/spinner.mp3";
import { endpoint } from "../../../assets/api/endpoint";
import { apiHandler } from "../../../assets/api";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { saveToken, saveUserId } from "../../../store/Reducers/testSlice";

const Spin = () => {
  const dispatch = useDispatch();
  const { token, userId } = useSelector((state) => state.game);

  const { search } = useLocation();
  const [selectedCoinAmount, setSelectedCoinAmount] = useState(10);
  const [totalAmount, setTotalAmount] = useState("");
  const [selectedNumbers, setSelectedNumbers] = useState({});
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [apiResponse, setApiResponse] = useState(null);
  const [apiResponseSession, setApiResponseSession] = useState(null);
  const [quntynums, setQuntynums] = useState([]);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [mustSpin, setMustSpin] = useState(false);
  const [spinNumber, setSpinNumber] = useState(5);
  const [currentSession, setCurrentSession] = useState(0);

  const convertQueryParamsStringToQueryParamsObject = (string) => {
    let queryParams = {};

    string = string.substring(1);

    const queryParamsArray = string.split("&");

    queryParamsArray.forEach((queryParam) => {
      const queryParamArray = queryParam.split("=");

      queryParams[queryParamArray[0]] = queryParamArray[1];
    });
    return queryParams;
  };
  const setParamData = (search) => {
    let queryParams = convertQueryParamsStringToQueryParamsObject(search);

    // save in redux
    dispatch(saveToken(queryParams.token));
    dispatch(saveUserId(queryParams.user_id));
  };
  useEffect(() => {
    setParamData(search);
  }, [search]);

  const onChnagequntynums = (value) => {
    setQuntynums(value);
  };

  const handleTotalAmount = (amount, value) => {
    setTotalAmount(amount);
  };

  const handleRefreshButton = () => {
    setSelectedCoinAmount("10");

    setTotalAmount("");
    setQuntynums([]);
    setSelectedNumbers({});
  };

  const toggleSound = () => {
    setSoundEnabled(!soundEnabled);
  };

  const playButtonClickSound = () => {
    if (soundEnabled) {
      const audio = new Audio(btnmusic);
      audio.play();
    }
  };

  const playSoundSpinner = () => {
    if (soundEnabled) {
      const audio = new Audio(spinSound);
      audio.play();
    }
  };
  const fetchUserSessionDetails = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("token", token);
      formData.append("session", currentSession + 1);

      const response = await axios.post(
        "https://buthiosrtl.app/ngame/app/winningj",
        formData
      );

      if (response.data.status === 1) {
        setApiResponseSession(response.data);

        setCurrentSession(currentSession + 1);
        setSpinNumber(
          response.data.winning && response.data.winning[0]
            ? response.data.winning[0].win
            : 5
        );
        setMustSpin(true);
      } else {
        console.log("error session");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchUserDetails = async () => {
    try {
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("token", token);

      const response = await axios.post(
        "https://buthiosrtl.app/ngame/app/userdetailj",
        formData
      );

      if (response.data.status === 1) {
        setApiResponse(response.data);

        if (currentSession !== 0) {
          // setSpinNumber(
          //   response.data.winning && response.data.winning[0]
          //     ? response.data.winning[0].win
          //     : 5
          // );
          // setMustSpin(true);
          setCurrentSession(response.data.previous_session);
        } else {
          setCurrentSession(response.data.previous_session);
          setSpinNumber(
            response.data.winning && response.data.winning[0]
              ? response.data.winning[0].win
              : 5
          );
          setMustSpin(true);
        }
      } else {
        logout();
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (userId && token) {
      fetchUserDetails();
    }
  }, [token]);

  const logout = () => {
    window.location.href = "https://buthiosrtl.app/game3";
  };
  // const updateSelectedNumbers = (number) => {
  //   let sn = { ...selectedNumbers };

  //   if (sn[number]) {
  //     if (sn[number].selectedCoinAmount === selectedCoinAmount) {
  //       sn[number].count = sn[number].count + 1 * selectedCoinAmount / 10;
  //     } else {
  //       sn[number].count = 1 * selectedCoinAmount / 10;
  //     }
  //     // if (sn[number].count === 1) {
  //     //   sn[number] = 1;
  //     // } else {
  //     //   sn[number] = { count: 1, selectedCoinAmount: selectedCoinAmount };
  //     // }
  //   } else {
  //     sn[number] = { count: 1 * selectedCoinAmount / 10, selectedCoinAmount: selectedCoinAmount };
  //   }
  //   setSelectedNumbers(sn);
  // };
  const updateSelectedNumbers = (number, action) => {
    let sn = { ...selectedNumbers };

    if (action === "increase") {
      if (sn[number]) {
        if (sn[number].selectedCoinAmount === selectedCoinAmount) {
          sn[number].count += selectedCoinAmount / 10;
        } else {
          sn[number].count = selectedCoinAmount / 10;
        }
      } else {
        sn[number] = {
          count: selectedCoinAmount / 10,
          selectedCoinAmount: selectedCoinAmount,
        };
      }
    } else if (action === "decrease") {
      if (sn[number]) {
        if (sn[number].count > selectedCoinAmount / 10) {
          sn[number].count -= selectedCoinAmount / 10;
        } else {
          delete sn[number];
        }
      }
    }

    setSelectedNumbers(sn);
  };

  return (
    <div className="spinback" style={{ height: "100vh" }}>
      <SpinHeader
        apiResponse={apiResponse}
        playButtonClickSound={playButtonClickSound}
      />
      <SpinMain
        selectedCoinAmount={selectedCoinAmount}
        handleTotalAmount={handleTotalAmount}
        playButtonClickSound={playButtonClickSound}
        playSoundSpinner={playSoundSpinner}
        apiResponse={apiResponse}
        fetchUserDetails={fetchUserDetails}
        onChnagequntynums={onChnagequntynums}
        selectedSlots={selectedSlots}
        setSelectedSlots={setSelectedSlots}
        selectedNumbers={selectedNumbers}
        setSelectedNumbers={updateSelectedNumbers}
        setQuntynums={setQuntynums}
        quntynums={quntynums}
        spinNumber={spinNumber}
        mustSpin={mustSpin}
        setMustSpin={setMustSpin}
        currentSession={currentSession}
        fetchUserSessionDetails={fetchUserSessionDetails}
      />

      <SpinFooter
        selectedCoinAmount={selectedCoinAmount}
        setSelectedCoinAmount={setSelectedCoinAmount}
        totalAmount={totalAmount}
        handleRefreshButton={handleRefreshButton}
        soundEnabled={soundEnabled}
        toggleSound={toggleSound}
        playButtonClickSound={playButtonClickSound}
        apiResponse={apiResponse}
        quntynums={quntynums}
        selectedSlots={selectedSlots}
        selectedNumbers={selectedNumbers}
      />
    </div>
  );
};

export default Spin;
