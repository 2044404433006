import React from 'react'
import "../assets/css/Login/login.css"

const CustumInput = ({placehold}) => {
  return (
 <>
   <div className="form-group">
            <input type="text" placeholder={placehold}className="input-box" style={{fontSize:"17px",padding:"4px 11px"}}/>
          </div>
 </>
  )
}

export default CustumInput
