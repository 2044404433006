import React, { useState } from "react";
import { BiSolidVolumeLow } from "react-icons/bi";
import coin1 from "../../../assets/image/olivecoin.png";
import coin2 from "../../../assets/image/purplecoin.png";
import coin3 from "../../../assets/image/skybluecoin.png";
import coin4 from "../../../assets/image/greycoin.png";
import coin5 from "../../../assets/image/redcoin.png";
import btnmusic from "../../../assets/music/click.mp3";
import { BiSolidVolumeMute } from "react-icons/bi";
import axios from "axios";
import { useSelector } from "react-redux";
import ClamModel from "./ClamModel";
import spinbtn from "../../../assets/image/spinbtnimg.png";

const SpinFooter = ({
  selectedCoinAmount,
  totalAmount,
  handleRefreshButton,
  soundEnabled,
  toggleSound,
  playButtonClickSound,
  apiResponse,
  quntynums,
  selectedSlots,
  selectedNumbers,
  setSelectedCoinAmount,
}) => {
  const { token, userId } = useSelector((state) => state.game);

  const defaultCoinAmount = apiResponse ? 0 : 0;
  const [clamResponse, setclamResponse] = useState(null);
  const [number, setNumber] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buyButtonDisabled, setBuyButtonDisabled] = useState(false);
  const [ClickTime, setClickTime] = useState(0);

  const handleChange = (event) => {
    setNumber(event.target.value);
  };

  if (!apiResponse) {
    return null;
  }
  const {
    name = "",
    wallet_bal = "",
    previous_session = "",
    ...rest
  } = apiResponse || {};

  const handleClick = (number) => {
    playButtonClickSound();
    setSelectedCoinAmount(number);
  };
  const ClaimFunction = async () => {
    playButtonClickSound();
    try {
      const formData = new FormData();

      formData.append("user_id", userId);
      formData.append("token", token);
      formData.append("transactionnumber", number);

      const response = await axios.post(
        "https://buthiosrtl.app/ngame/app/checkwinj",
        formData
      );

      if (response.data.status === 1) {
        setclamResponse(response.data);
        setShowModal(true);

        setNumber("");
      } else {
        setclamResponse(response.data);
        setShowModal(true);
        console.log("error");
        setNumber("");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlerefresh = () => {
    playButtonClickSound();
    handleRefreshButton();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log('TIME - ', Date.now() - ClickTime)
    if (ClickTime === 0) setClickTime(Date.now());
    else {
      setClickTime(Date.now());
      if ((Date.now() - ClickTime) < 3000) {
        return;
      }
    }
    // console.log('BUY CALLED');
    try {
      const lttrynus = [...Object.values(selectedNumbers)];
      const advancegm = selectedSlots.map((o) => o.sessionID);
      const lt = [...Object.keys(selectedNumbers)];
      setBuyButtonDisabled(true);
      playButtonClickSound();
      const formData = new FormData();
      formData.append("user_id", userId);
      formData.append("token", token);
      formData.append("tickpriceidss", 10);
      formData.append("useridddss", userId);
      // formData.append("advancegamess", advancegm);
      if (advancegm.length === 0) {
        formData.append("advancegamess", []);
      } else {
        advancegm.forEach((s, i) => {
          formData.append(`advancegamess[${i}]`, s);
          // console.log(`advancegamess[${i}]`, s);
        });
      }
      if (lttrynus.length === 0) {
        formData.append("lttrynumsss[0]", []);
        formData.append("lttrynumsqntss[0]", []);
      } else {
        lt.forEach((val, index) => {
          formData.append(`lttrynumsss[${index}]`, val);
          // console.log(`lttrynumsss[${index}]`, val);
          formData.append(`lttrynumsqntss[${index}]`, lttrynus[index].count);
          // console.log(`lttrynumsqntss[${index}]`, lttrynus[index].count);
        });
      }

      const response = await axios.post(
        "https://buthiosrtl.app/ngame/app/buytnticket",
        formData
      );
      setBuyButtonDisabled(false);
      if (response.data.status === 1) {
        const length =
          response.data.ticket_book && response.data.ticket_book[0]
            ? response.data.ticket_book.length
            : 1;
        // alert(`https://buthiosrtl.app/ngame/laptop_joker/buy_reciept?id=${response.data.ticket_book.join(",")}&length=${length}&token=${token}&user_id=${userId}`);
        window.location.href = `https://buthiosrtl.app/ngame/laptop_joker/buy_reciept?id=${response.data.ticket_book.join(
          ","
        )}&length=${length}&token=${token}&user_id=${userId}`;
      } else {
        console.log("Error");
      }
    } catch (error) {
      setBuyButtonDisabled(false);
      console.error("Error:", error);
    } finally {
      setBuyButtonDisabled(false);
    }
  };

  return (
    <div className="container-fluid">
      <div className="footer-container">
        <div className="footer-row">
          <div
            className="footer-col-2 button-container"
            style={{ position: "relative" }}
          >
            <p
              className="refresh-btn"
              onClick={handlerefresh}
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                margin: "0",
              }}
            >
              Refresh
            </p>
            <img
              src={spinbtn}
              style={{ display: "block", margin: "0 auto", height: "6vh" }}
            />
          </div>
          <div className="footer-col-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  margin: "auto",
                }}
                onClick={() => handleClick(10)}
              >
                <img
                  src={coin1}
                  alt="coin1"
                  className={`seletedcoinwidth ${
                    selectedCoinAmount === 10 ? "selectedspinamountcoin" : ""
                  }`}
                />
                <p className="absolute-center">10</p>
              </div>

              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  margin: "auto",
                }}
                onClick={() => handleClick(20)}
              >
                <img
                  src={coin2}
                  alt="coin1"
                  className={`seletedcoinwidth ${
                    selectedCoinAmount === 20 ? "selectedspinamountcoin" : ""
                  }`}
                />
                <p className="absolute-center">20</p>
              </div>

              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  margin: "auto",
                }}
                onClick={() => handleClick(30)}
              >
                <img
                  src={coin3}
                  alt="coin1"
                  className={`seletedcoinwidth ${
                    selectedCoinAmount === 30 ? "selectedspinamountcoin" : ""
                  }`}
                />
                <p className="absolute-center">30</p>
              </div>

              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  margin: "auto",
                }}
                onClick={() => handleClick(40)}
              >
                <img
                  src={coin4}
                  alt="coin1"
                  className={`seletedcoinwidth ${
                    selectedCoinAmount === 40 ? "selectedspinamountcoin" : ""
                  }`}
                />
                <p className="absolute-center">40</p>
              </div>

              <div
                style={{
                  position: "relative",
                  textAlign: "center",
                  margin: "auto",
                }}
                onClick={() => handleClick(50)}
              >
                <img
                  src={coin5}
                  alt="coin1"
                  className={`seletedcoinwidth ${
                    selectedCoinAmount === 50 ? "selectedspinamountcoin" : ""
                  }`}
                />
                <p className="absolute-center">50</p>
              </div>
            </div>
          </div>

          <div
            className="footer-col-2 button-container"
            style={{ position: "relative" }}
          >
            <p
              className="refresh-btn"
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                margin: "0",
              }}
              onClick={(e) => handleSubmit(e)}
              disabled={buyButtonDisabled}
            >
              Buy
            </p>
            <img
              src={spinbtn}
              style={{ display: "block", margin: "0 auto", height: "6vh" }}
            />
          </div>

          <div className="footer-col-1">
            {soundEnabled ? (
              <BiSolidVolumeLow className="music-icon" onClick={toggleSound} />
            ) : (
              <BiSolidVolumeMute className="music-icon" onClick={toggleSound} />
            )}
          </div>
          <div className="footer-col-2 form-switch">
            <input
              className="form-controls form-control"
              placeholder="barcode"
              style={{ textAlign: "center" }}
              onChange={handleChange}
              value={number}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  ClaimFunction();
                }
              }}
            />
          </div>
          <div class="footer-col-1 button-container">
            <button className="clam-btn" onClick={ClaimFunction}>
              Claim
            </button>
          </div>
        </div>
      </div>
      {showModal && (
        <ClamModel setShowModal={setShowModal} clamResponse={clamResponse} />
      )}
    </div>
  );
};

export default SpinFooter;
