import React from "react";

const SpinButtons = ({
  playButtonClickSound,
  selectedNumbers,
  setSelectedNumbers,
}) => {
  const handleLeftClick = (number) => {
    playButtonClickSound();
    setSelectedNumbers(number, "increase");
  };

  const handleRightClick = (number, event) => {
    event.preventDefault();
    playButtonClickSound();
    setSelectedNumbers(number, "decrease");
  };

  const buttons = [];
  for (let i = 1; i <= 12; i++) {
    buttons.push(
      <button
        key={i}
        className={`buttonssplit ${
          Object.keys(selectedNumbers).includes(i + "")
            ? "selectedspinbuttons"
            : ""
        }`}
        onClick={() => handleLeftClick(i)}
        onContextMenu={(event) => handleRightClick(i, event)}
      >
        {i}
      </button>
    );
  }

  return (
    <div style={{ height: "9vh" }}>
      <div className="row">
        <div style={{ display: "flex" }}>{buttons}</div>
      </div>
    </div>
  );
};

export default SpinButtons;
