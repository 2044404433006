import React, { useState, useEffect } from "react";
import "../../../assets/css/RetalierBonus/retailerbonuspop.css";

const Modal = ({ onClose }) => {
  const [countdown, setCountdown] = useState(10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      onClose();
    }
  }, [countdown, onClose]);

  return (
    <>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block", top: "30%" }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "350px" }}
        >
          <div
            className="modal-content"
            style={{ opacity: "0.8", background: "transparent", border: "0px" }}
          >
            <div className="">
              <div className="outerbodyss">
                <div className="popup">
                  <p className="counttext">{countdown}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
