import React from "react";
import "../../../assets/css/jocker/clammodel.css";

const ClamModel = ({ setShowModal, clamResponse }) => {

  return (
    <>
      <div
        className="modal"
        tabIndex="-1"
        role="dialog"
        style={{ display: "block", top: "30%" }}
      >
        <div
          className="modal-dialog"
          role="document"
          style={{ width: "350px" }}
        >
          <div
            className="modal-content"
            style={{ opacity: "0.8", boxShadow: "0 0 20px #3483cc" }}
          >
            <div className="modal-body">
              <div className="outerbody">
                <div className="popup">
                  <div className="bonuswriting">
                    {clamResponse.claim_status}
                  </div>

                  <button
                    type="button"
                    className="btn btn-primary btncls"
                    onClick={() => setShowModal(false)}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClamModel;
