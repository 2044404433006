import React from 'react'
import "../../assets/css/Login/login.css"
import CustumInput from '../../components/CustumInput'
import CustumButton from '../../components/CustumButton'
import { NavLink } from 'react-router-dom'


const Login = () => {
  return (
   <>
   <div className='containers'>
   <div className='text-white text-center tex'>
   All Right Reserved | Gold coin
   </div>
    <div className='text-white text-center textt'>
    This game is only for entertainment & only applicable for individuals above 18 years of age. User play at own risk .
    </div>
 
   <div className="login-container">
 
        <h1 className="login-heading">Login</h1>
        <form>
            <hr className='hrline'></hr>
        <CustumInput placehold="login ID"/>
        <CustumInput placehold="Password"/>
          <hr className='hrlinee'></hr>
<NavLink to="/jocker-main">    <CustumButton/></NavLink>

        </form>
     
      </div>

    </div>
   </>
  )
}

export default Login
